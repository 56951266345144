import React from "react";
import {
  Navbar,
  Footer,
  Welcome,
} from "./components";
import "./App.css";

function App() {
  return (
    <div className="min-h-screen flex flex-col">
      <div className="gradient-bg-welcome relative  flex-grow">
        <Navbar />
        <Welcome />
      </div>
      <Footer />
    </div>
  );
}

export default App;
