import { FaDiscord, FaTwitter, FaInstagram } from "react-icons/fa";

const Footer = () => (
  <div className="w-full flex md:justify-center justify-between items-center flex-col p-4 gradient-bg-footer">
    <div className="max-w-6xl w-full flex sm:flex-row flex-col justify-between my-4">
      {/* <img src={logo} alt="logo" className="w-32" /> */}
      <div className="md:flex-[0.5] flex-initial justify-center items-center text-white text-2xl sm:text-3xl py-1 font-semibold">
        Koinos Bridge
      </div>

      <div className="flex flex-1 items-center justify-end">
        <FaDiscord className="text-white mr-4" />
      </div>
    </div>

    <div className="max-w-6xl flex justify-center items-center flex-col mt-5">
      <p className="text-white text-sm text-center font-medium mt-2"></p>
    </div>

    <div className="max-w-6xl sm:w-[90%] w-full h-[0.25px] bg-gray-400 mt-5 " />

    <div className="max-w-6xl sm:w-[90%] w-full flex justify-between items-center mt-3">
      <p className="text-white text-left text-xs">Koinos Bridge 2022</p>
      <p className="text-white text-right text-xs">All rights reserved</p>
    </div>
  </div>
);

export default Footer;
